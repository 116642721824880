import dayjs from "dayjs";

/**
 * set locale language.
 */
export default class SetLocalCodeHelper {
    static setLocalLanguage = (currentLocaleCode = "en") => {
        if (currentLocaleCode && currentLocaleCode !== "en") {
            // set default locale for dayjs
            try {
                require("dayjs/locale/" + currentLocaleCode);
                dayjs.locale(currentLocaleCode);
            }
            catch (error) {
                console.log("error while attempting loading dayjs locale", currentLocaleCode, error);
            }
        }
    };
}
